export enum LangEnum {
    CA = 'ca',
    DE = 'de',
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    HR = 'hr',
    IT = 'it',
    JA = 'ja',
    KO = 'ko',
    OC = 'oc',
    PT = 'pt',
    RU = 'ru',
    SL = 'sl',
    SV = 'sv',
    TH = 'th',
    TR = 'tr',
    ZH_SIMPLIFY = 'zh-Hans',
    ZH_TRADITIONAL = 'zh-Hant',
}
