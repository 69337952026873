import { ReactElement, useRef, useState } from 'react';
import Image from 'next/image';
// Design systems
import { Font } from 'src/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
import { LanguageSelector } from '../../language-selector/LanguageSelector';
import { Link } from 'src/design-system/link/link';
import isEmpty from 'lodash.isempty';
// Features
import MegaNavDesktop from '@utmb/features/mega-nav/MegaNavDesktop';
import { LiveBanners } from '@utmb/features/live-banners/LiveBanners';
import { MyAccountDropdown } from '@utmb/features/layout/my-account-dropdown/MyAccountDropdown';
// Context
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Interfaces
import { ColorTypesEnum } from '@utmb/types/colors';
import { EventView } from '@utmb/types/index';
import { IconsEnum } from '@utmb/types/icons';
// Utils
import { getColorStyle } from '@utmb/utils/colors';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// Styles
import styles from './top-bar.module.scss';
import { useRouter } from 'next/router';
import { MyParameters } from '../../my-parameters/MyParameters';

interface TopBarProps {
    eventName: string;
    eventsTopBar: EventView[];
    className?: string;
    languages: string[];
    translationSlugs?: {
        [key: string]: string;
    };
}

export const TopBar = (props: TopBarProps): ReactElement => {
    const { eventsTopBar, languages, translationSlugs } = props;

    const refMegaNav = useRef<HTMLDivElement>(null);
    const {
        global: {
            event: { isWS },
        },
    } = useGlobal();

    const { locale, defaultLocale } = useRouter();

    const [isMyAccountDropdownShow, myAccountDropdownShow] = useState(false);
    const [isMegaNavOpen, setIsMegaNavOpen] = useState(false);

    function handleMyAccountClick(e: any): boolean {
        if (e.type === 'keydown' && e.keyCode !== 13) {
            return false;
        }

        myAccountDropdownShow(!isMyAccountDropdownShow);

        return true;
    }

    function handleMegaNavToggle(e: any): boolean {
        if (typeof e !== 'boolean' && e.type === 'keydown' && e.keyCode !== 13) {
            return false;
        }

        const currentIsMegaNavOpen = !isMegaNavOpen;

        setIsMegaNavOpen(currentIsMegaNavOpen);

        if (refMegaNav.current) {
            if (currentIsMegaNavOpen) {
                disableBodyScroll(refMegaNav.current);
            } else {
                enableBodyScroll(refMegaNav.current);
            }
        }

        return true;
    }

    return (
        <div className={`row ${styles.top_nav_wrapper}`}>
            <div>
                <div className={styles.left_content_wrapper}>
                    <div className={styles.utmb_link}>
                        <div className={styles.logo}>
                            <Image width="24" height="24" src="/svg/utmb-logo.svg" alt="UTMB Logo Icon" />
                        </div>
                        <Link href={isWS ? `/${locale !== defaultLocale ? locale : '' ?? ''}` : 'https://utmb.world/'}>
                            <Font.FuturaMedium className={styles.event_name} mobile="13" as="span">
                                UTMB<sup>®</sup> World
                            </Font.FuturaMedium>
                        </Link>
                    </div>
                    <div className={styles.separator} />
                    {/* Race Selector */}
                    {!isEmpty(eventsTopBar) && (
                        <>
                            <div role="button" tabIndex={0} className={styles.mega_nav_link} onClick={handleMegaNavToggle} onKeyDown={handleMegaNavToggle}>
                                <Font.FuturaMedium mobile="13" as="span">
                                    UTMB World Series Events
                                </Font.FuturaMedium>
                                <Icon variant={IconsEnum.CHEVRON_RIGHT} />
                            </div>
                            <MegaNavDesktop ref={refMegaNav} isOpen={isMegaNavOpen} eventsTopBar={eventsTopBar} handleClose={handleMegaNavToggle} />
                        </>
                    )}
                </div>
            </div>
            <div>
                <LiveBanners />
            </div>
            <div>
                <div className={styles.right_content_wrapper}>
                    <MyParameters className={styles.selector} />
                    <LanguageSelector className={styles.selector} languages={languages} translationSlugs={translationSlugs} />

                    <div role="button" tabIndex={0} onClick={handleMyAccountClick} onKeyDown={handleMyAccountClick} className={styles.topNav_dashboard}>
                        <Font.FuturaMedium mobile="16" as="span">
                            MY
                        </Font.FuturaMedium>
                        &nbsp;
                        <Font.FuturaBold mobile="16" as="span">
                            UTMB
                        </Font.FuturaBold>
                        <Icon variant={IconsEnum.USER} color={getColorStyle(ColorTypesEnum.WS_PRIMARY)} />
                        <Icon clss={styles.svg_chevron} variant={IconsEnum.CHEVRON_RIGHT} color={getColorStyle(ColorTypesEnum.WS_PRIMARY)} />
                    </div>
                    <MyAccountDropdown isOpen={isMyAccountDropdownShow} />
                </div>
            </div>
        </div>
    );
};
