/* eslint-disable no-case-declarations */
import React, { ReactElement } from 'react';
// Interfaces
import { SliceAccordionView, SliceAccordionItemView, SliceTypeEnum, SliceView } from '@utmb/types/index';
import { MicroDataFaq } from '@utmb/types/microdata';
import { parseMarkdown } from '@utmb/utils/markdown';

// Contains components that render slices
// const componentsRecursive = [SliceTypeEnum.BACKGROUND];

interface SlicesHandlerProps {
    slices: SliceView[];
}

export const FaqMicrodata = (props: SlicesHandlerProps): ReactElement => {
    const { slices = [] } = props;

    const accordions = slices.filter((s) => s.type === SliceTypeEnum.ACCORDION);
    slices.forEach((s) => {
        if (s.type === SliceTypeEnum.BACKGROUND) {
            if (s.data && 'slices' in s.data) {
                s.data.slices.forEach((s2) => {
                    if (s2.type === SliceTypeEnum.ACCORDION) accordions.push(s2);
                });
            }
        }
    });

    const faqMicroData: { question: string; answer: string }[] = [];

    accordions.forEach((slice) => {
        const { items } = { ...(slice.data as SliceAccordionView) };
        items.forEach((item: SliceAccordionItemView) => {
            if (item.title.includes('?')) {
                faqMicroData.push({ question: item.title, answer: item.content });
            }
        });
    });

    return (
        <>
            {faqMicroData.length > 0 && (
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(buildFaqMicroData(faqMicroData)) }} />
            )}
        </>
    );
};

const buildFaqMicroData = (faqmd: { question: string; answer: string }[]): MicroDataFaq => {
    const faq: MicroDataFaq = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [],
    };
    faqmd.forEach(({ question, answer }) =>
        faq.mainEntity.push({
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: parseMarkdown(answer),
            },
        })
    );

    return faq;
};
