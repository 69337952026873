import { ReactElement } from 'react';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Link } from '@utmb/design-system/link/link';
import { Icon } from '@utmb/design-system/icons/Icon';
// Interfaces
import { IconsEnum } from '@utmb/types/icons';
// Styles
import styles from './top-bar.module.scss';

interface TopBarProps {
    handleMegaNavToggle: () => void;
}

export const TopBar = (props: TopBarProps): ReactElement => {
    const { handleMegaNavToggle } = props;

    return (
        <div className={styles.container}>
            <Link href="https://utmb.world/" className={styles.button}>
                <Icon variant={IconsEnum.LOGO_SIMPLIFIED_DARK} />
                <Font.FuturaMedium mobile="13" as="span">
                    UTMB<sup>®</sup> World
                </Font.FuturaMedium>
            </Link>
            <div className={styles.separator}></div>
            <button className={styles.button} onClick={handleMegaNavToggle} onKeyDown={handleMegaNavToggle}>
                <Font.FuturaMedium mobile="13" as="span">
                    UTMB<sup>®</sup> Events
                </Font.FuturaMedium>
                <Icon variant={IconsEnum.CHEVRON_RIGHT} />
            </button>
        </div>
    );
};
