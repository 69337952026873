import { COOKIE_PRO_ID } from '../../constants/cookiePro';
import Script from 'next/script';
import { FC } from 'react';

const CookiePro: FC = () => {
    const isDev = process.env.NODE_ENV === 'development';

    return (
        <>
            {!isDev ? (
                <>
                    <Script async={false} src={`https://cdn.cookielaw.org/consent/${COOKIE_PRO_ID}/OtAutoBlock.js`} charSet={'UTF-8'} />
                    <Script
                        async={false}
                        src={`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`}
                        type="text/javascript"
                        charSet={'UTF-8'}
                        data-domain-script={COOKIE_PRO_ID}
                    />
                    <Script
                        id="show-banner"
                        dangerouslySetInnerHTML={{
                            __html: `function OptanonWrapper()
                            {window.dataLayer.push({event:'OneTrustGroupsUpdated'})}`,
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default CookiePro;
